import {View, Text, StyleSheet, Image} from "react-native-web";
import {useEffect, useState} from "react";
import {getBizOverview} from "../services/BizService";
import Button from "./Button";
import Modal from "react-modal";

const BusinessHub  = () => {
    const [businesses, setBusinesses] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false)
    const [selectedBiz, setSelectedBiz] = useState({})

    function afterOpenModal() {

    }

    function closeModal() {
        setDeleteModal(false);
    }

    function openDeleteModal(biz) {
        setSelectedBiz(biz);
        setDeleteModal(true);
    }

    useEffect(()=>{
        const token = localStorage.getItem("admin-token");
        getBizOverview(token)
            .then(resp => {
                if (resp.status === 200 && resp.data) {
                    setBusinesses(resp.data);
                }
            })
    },[]);

    return (
        <View>
            {businesses && businesses.map(business => (
                <View style={{flexDirection: 'row', alignItems: 'center',  border: '1px solid rgba(57, 109, 224, .3)', marginBottom: 10,}}>
                    <Image
                        source={{uri: "https://d3p3fw3rutb1if.cloudfront.net/business/photos/" + business.businessId}}
                        style={styles.creatorPreviewImage}
                    />
                    <Text>{business.name}</Text>
                    <View style={{marginLeft: 15}}>
                        <Text>Campaigns</Text>
                        <Text>{business.campaignCount}</Text>
                    </View>
                    <View style={{marginLeft: 15}}>
                        <Text>Users</Text>
                        <Text>{business.userCount}</Text>
                    </View>
                    <View style={{marginLeft: 15}}>
                        <Text>Subscription Price</Text>
                        <Text>{business.subscriptionPrice}</Text>
                    </View>
                    <View style={{marginLeft: 15}}>
                        <Text>Subscription</Text>
                        <Text>{business.subscriptionName}</Text>
                    </View>
                    <View style={{marginLeft: 15}}>
                        <Text>Subscription Status</Text>
                        <Text>{business.stripeSubscriptionStatus}</Text>
                    </View>
                    <View>
                        <Button text={'Disable Business'}/>
                        <Button variant={'warning'} text={'Delete Business'} onPress={() => {openDeleteModal(business)}}/>
                    </View>
                </View>
            ))}
            <Modal
                isOpen={deleteModal}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                className={"invite-modal"}
                style={styles.modal}
            >
                <View style={{alignItems: 'center', backgroundColor: 'white', borderRadius: 10, padding: 20}}>
                    <Text>Are you sure you want to delete: {selectedBiz.name}?</Text>
                </View>
            </Modal>
        </View>
    )
}

const styles = StyleSheet.create({
    creatorPreviewImage: {
        width: 80,
        height: 80,
        margin: 10,
        borderRadius: 50,
    },
    modal: {
        overlay: {
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignContent: 'center',
            backgroundColor: 'rgba(57, 109, 224, .5)'
        },
        content: {
            margin: '20%',
            inset: 0,
            background: 'none',
            flex: 1,
            border: 'none',
            alignSelf: 'center',
            alignContent: 'center',
            outline: 'none',
        }
    },
});

export default BusinessHub
import React, {Fragment, useEffect, useRef, useState} from 'react'
import {Link, Outlet, useNavigate, useSearchParams} from "react-router-dom";
import '../stylesheets/NavBar.css';
import Dropdown from 'react-bootstrap/Dropdown';


const NavBar = ({searchFunc}) => {
    const [user, setUser] = React.useState("");
    const [sidebarOpen, setSidebarOpen] = React.useState(false);
    const [searchInput, setSearchInput] = React.useState("");
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const ref = useRef(null);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            searchFunc(event.target.value)
        }
    }

    const updateSearch = (event) => {
        setSearchInput(event.target.value)
    }

    function logout() {
        localStorage.removeItem('admin-token');
        navigate(0);
    }

    function openSidebar() {
        setSidebarOpen(true);
    }

    function closeSidebar() {
        setSidebarOpen(false);
    }

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
        </a>
    ));

    useEffect(() => {
        const loggedInUser = localStorage.getItem("user");
        if (loggedInUser) {
            const foundUser = JSON.parse(loggedInUser);
            setUser(foundUser);
        }
        const search = searchParams.get("search")
        if (search) {
            setSearchInput(search);
            searchFunc(search);
        }
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                closeSidebar();
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return(
        <div>
            <nav className="navbar navbar-light dupe-navbar">
                <div className="col-md-6">
                    <div className="mb-6 search-bar">
                        <div className="dupe-logo">
                            <a href="/">
                                <span className="input-group-text" id="basic-addon3">Dupe</span>
                            </a>
                        </div>
                        <input type="text" className="form-control" placeholder="whats the vibe?" value={searchInput} onChange={updateSearch} aria-describedby="basic-addon3" onKeyDown={handleKeyDown}/>
                    </div>

                </div>
                <div className="col-md-4">
                    <div className="form links">
                        <Link to="/">
                            <p className={"text-link"}>RATE PHOTOS</p>
                        </Link>
                        <Link to="/flagged">
                            <p className={"text-link"}>FLAGGED PHOTOS</p>
                        </Link>
                        <Link to="/users">
                            <p className={"text-link"}>USERS</p>
                        </Link>
                        <Link to="/reported">
                            <p className={"final-text-link"}>REPORTED CONTENT</p>
                        </Link>
                    </div>
                </div>
                <div className="col-md-2">
                    <div className={"profile"}>
                        <Dropdown>
                            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                <div className="pull-left">
                                    <img className="thumbnail-image"
                                         src={"data:" + user.profile_image_type + ";base64," + user.profile_image}
                                         alt="user pic"
                                    />
                                    <img className="down-arrow"
                                         src="https://www.clipartmax.com/png/middle/118-1181112_chevron-icon-drop-down-menu-arrow.png"
                                         alt="user pic"
                                    />
                                </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item eventKey="1" onClick={(e) => {navigate('/settings')}}>Settings</Dropdown.Item>
                                <Dropdown.Item eventKey="2" onClick={(e) => {navigate('/aesthetics')}}>Aesthetics</Dropdown.Item>
                                <Dropdown.Item eventKey="3" onClick={(e) => {navigate('/aesthetics/submissions')}}>Aesthetic Submissions</Dropdown.Item>
                                <Dropdown.Item eventKey="4" onClick={(e) => {navigate('/homepage')}}>Update Homepage</Dropdown.Item>
                                <Dropdown.Item eventKey="5" onClick={(e) => {navigate('/tag_editor')}}>Edit Tags</Dropdown.Item>
                                <Dropdown.Item eventKey="5" onClick={(e) => {navigate('/biz')}}>Dupe Biz</Dropdown.Item>
                                <Dropdown.Item eventKey="6" onClick={logout}>Logout</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </nav>
            <Outlet/>
        </div>
    );
}

export default NavBar
